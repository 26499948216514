import { FormOutlined, LoadingOutlined, LogoutOutlined, MehOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import Icon from 'components/util-components/Icon';
import FacilityListContext from 'contexts/facilityListContext';
import * as moment from 'moment';
import { memo, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFacilityDetail } from 'redux/actions/Getfacility.action';
import { getUserProfile } from '../../redux/actions/userProfile.action';
import { getUserId, isValidArray } from '../../utils/MTagUtil';

const menuItem = [
	{
		title: 'Your Profile',
		icon: MehOutlined,
		path: '/app/profile',
	},
	{
		title: 'Edit Profile',
		icon: FormOutlined,
		path: '/app/profile-edit',
	},
	{
		title: 'Sign Out',
		icon: LogoutOutlined,
		path: '/app/logout',
	},
];

export const NavProfile = props => {
	const dispatch = useDispatch();
	const userProfileInfo = useSelector(state => state.userProfile);
	// const getAllFacilityDetails = useSelector(state => state.getAllFacilityDetails);

	// const [facilityListData, setFacilityListData] = useState([]);

	const { setSelectedFacility, setFacilityListData } = useContext(FacilityListContext);

	useEffect(() => {
		if (localStorage.getItem('user_type') !== 'A') {
			let body = {
				userType: localStorage.getItem('user_type'),
				userId: localStorage.getItem('user_id'),
				groupId: localStorage.getItem('group_id'),
			};
			dispatch(getFacilityDetail(body.userId)).then(({ payload }) => {
				console.log('🚀 ~ dispatch ~ payload:', payload);
				setFacilityListData(payload.result);
				setSelectedFacility(payload.result[0].id);
			});
		}
		return () => {};
	}, []);

	// useEffect(() => {
	// 	if (localStorage.getItem('user_type') !== 'A') {
	// 		const { error, isLoading, data } = getAllFacilityDetails;
	// 		const facilityData = !error && !isLoading ? data : {};
	// 		const { result } = facilityData;
	// 		setSelectedFacility(result && result?.[0] ? result[0] : []);
	// 	}
	// }, [getAllFacilityDetails]);

	useEffect(() => {
		dispatch(getUserProfile(getUserId()));
		return () => {};
	}, []);

	const getUserName = useCallback(
		userInfo => {
			const { data, error, isLoading } = userInfo;

			if (!isLoading && !error) {
				const { result } = data;
				const { name } = isValidArray(result) && result[0];
				return name;
			}

			return <LoadingOutlined style={{ fontSize: 24 }} spin />;
		},
		[userProfileInfo],
	);

	// const getFacilityData = useCallback(() => {
	// 	const { error, isLoading, data } = getAllFacilityDetails;
	// 	const facilityData = !error && !isLoading ? data : {};
	// 	const { result } = facilityData;
	// 	console.log('🚀 ~ file: NavProfile.js:71 ~ getFacilityData ~ result:', result);
	// 	return isValidArray(result) ? result : [];
	// }, [getAllFacilityDetails]);

	const getCurrentTime = () => {
		return moment().format('dddd, DD MMMM YYYY');
	};

	// const profileImg = '/img/avatars/thumb-1.jpg';
	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-body">
				<Menu>
					{menuItem.map((el, i) => {
						return (
							<Menu.Item key={i}>
								<Link to={el.path}>
									<Icon className="mr-3" type={el.icon} />
									<span className="font-weight-normal">{el.title}</span>
								</Link>
							</Menu.Item>
						);
					})}
				</Menu>
			</div>
		</div>
	);

	return (
		<div className="d-flex w-100 align-items-center justify-content-between">
			{/* {localStorage.getItem('user_type') !== 'A' && facilityListData && facilityListData.length > 1 ? (
				<div className="w-100">
					<Select className="w-100" defaultValue={facilityListData[0].id} onChange={setSelectedFacility}>
						{facilityListData.map(({ id, name }) => {
							return (
								<Select.Option key={id} value={id}>
									{name}
								</Select.Option>
							);
						})}
					</Select>
				</div>
			) : (
				<div>
					<h4 className="m-0">{facilityListData && facilityListData.length > 0 && <b>{facilityListData[0].name}</b>}</h4>
				</div>
			)} */}
			<Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
				<Menu className="d-flex align-item-center" mode="horizontal">
					<Menu.Item>
						<Avatar src="/img/avatars/icon.jpg" />
					</Menu.Item>
					<Menu.Item className="pl-0">
						<div className="mt-3" style={{ lineHeight: 1.3 }}>
							<small className="mb-0" style={{ fontSize: 'inherit' }}>
								{getCurrentTime()}
							</small>
							<h4 className="mb-0">
								<span className="font-weight-normal">Welcome,</span> <b>{getUserName(userProfileInfo)}</b>
							</h4>
						</div>
					</Menu.Item>
				</Menu>
			</Dropdown>
		</div>
	);
};

export default memo(NavProfile);
