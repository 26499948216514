import { EDIT_CONTRACTED_HOURS_4_GRP_FAILURE, EDIT_CONTRACTED_HOURS_4_GRP_SUCCESS } from '../constants/MTag';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
	switch (action.type) {
		case EDIT_CONTRACTED_HOURS_4_GRP_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
			};
		case EDIT_CONTRACTED_HOURS_4_GRP_FAILURE:
			return {
				...state,
				isLoading: false,
				errorData: action.payload,
				error: true,
			};
		default:
			return { ...state };
	}
};
