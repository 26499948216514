import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route exact path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboards`))} />
				<Route exact path={`${APP_PREFIX_PATH}/licence-details`} component={lazy(() => import('./licence'))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/garmentwash`} component={lazy(() => import(`./report/GarmentReports/resident/GarmentWash`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/daily/garmentwash`} component={lazy(() => import(`./report/GarmentReports/daily/DailyGarmentWash`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/weekly/garmentwash`} component={lazy(() => import(`./report/GarmentReports/weekly/WeeklyGarmentWash`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/monthly/garmentwash`} component={lazy(() => import(`./report/GarmentReports/monthly/index`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/summary`} component={lazy(() => import(`./report/GarmentReports/summary/index.js`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/residentlisting`} component={lazy(() => import(`./report/ResidentListing`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/residentclothlisting`} component={lazy(() => import(`./report/ResidentClothListing`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/ledmapping`} component={lazy(() => import(`./report/LEDMapping`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/tracked-items`} component={lazy(() => import(`./report/lostItemsFound`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/daily-delivered-cloths`} component={lazy(() => import(`./report/clothsDelivered`))} />
				<Route exact path={`${APP_PREFIX_PATH}/report/resident-wise-delivered-cloths`} component={lazy(() => import(`./report/ResidentWiseClothDelivered`))} />
				<Route exact path={`${APP_PREFIX_PATH}/logout`} component={lazy(() => import(`../../views/auth-views/components/Logout`))} />
				<Route exact path={`${APP_PREFIX_PATH}/profile-edit`} component={lazy(() => import(`../../views/app-views/profile/editProfile`))} />
				<Route exact path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`../../views/app-views/profile/yourProfile`))} />
				<Route exact path={`${APP_PREFIX_PATH}/facility`} component={lazy(() => import(`../../views/app-views/facility/viewFacility`))} />
				<Route exact path={`${APP_PREFIX_PATH}/facility/add`} component={lazy(() => import(`../../views/app-views/facility/addFacility`))} />
				<Route exact path={`${APP_PREFIX_PATH}/facility/edit/:id`} component={lazy(() => import(`../../views/app-views/facility/addFacility`))} />
				<Route exact path={`${APP_PREFIX_PATH}/licence/add`} component={lazy(() => import(`../../views/app-views/licence/manageLicence`))} />
				<Route exact path={`${APP_PREFIX_PATH}/licence`} component={lazy(() => import(`../../views/app-views/licence/viewLicence`))} />
				<Route exact path={`${APP_PREFIX_PATH}/rfid-reader`} component={lazy(() => import(`../../views/app-views/RFIDReader/index`))} />
				<Route exact path={`${APP_PREFIX_PATH}/rfid-reader/add`} component={lazy(() => import(`../../views/app-views/RFIDReader/manageReader`))} />
				<Route exact path={`${APP_PREFIX_PATH}/rfid-reader/edit/:name/:id`} component={lazy(() => import(`../../views/app-views/RFIDReader/manageReader`))} />
				<Route exact path={`${APP_PREFIX_PATH}/facility-management`} component={lazy(() => import(`../../views/app-views/dashboards/group-dashboard/facility-management`))} />
				<Route exact path={`${APP_PREFIX_PATH}/group-user-management`} component={lazy(() => import(`../../views/app-views/dashboards/group-dashboard/user-management`))} />

				<Route exact path={`${APP_PREFIX_PATH}/group`} component={lazy(() => import(`../../views/app-views/group/viewGroup`))} />
				<Route exact path={`${APP_PREFIX_PATH}/group/add`} component={lazy(() => import(`../../views/app-views/group/addGroup`))} />
				<Route exact path={`${APP_PREFIX_PATH}/group/edit/:id`} component={lazy(() => import(`../../views/app-views/group/addGroup`))} />
				<Route exact path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`../../views/app-views/user/viewUser`))} />
				<Route exact path={`${APP_PREFIX_PATH}/user/add`} component={lazy(() => import(`../../views/app-views/user/addUser`))} />
				<Route exact path={`${APP_PREFIX_PATH}/licence/edit/:id`} component={lazy(() => import(`../../views/app-views/licence/manageLicence`))} />
				<Route exact path={`${APP_PREFIX_PATH}/user-management`} component={lazy(() => import(`./userManagement/viewUserList`))} />

				<Route exact path={`${APP_PREFIX_PATH}/user-management/add`} component={lazy(() => import(`./userManagement/addUser`))} />

				<Route exact path={`${APP_PREFIX_PATH}/user-management/edit/:id`} component={lazy(() => import(`../../views/app-views/userManagement/addUser`))} />

				<Redirect exact from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
